import { HappenedYesterdayService } from './../happened-yesterday.service';
import { BusinessUnitLocationsService } from 'src/app/business-unit-locations/business-unit-locations.service';
import { AdminService } from 'src/app/admin/admin.service';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { formatDate } from '@angular/common';
import { EChartsOption } from 'echarts';
import { DatePipe } from '@angular/common';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-happened-week',
  templateUrl: './happened-week.component.html',
  styleUrls: ['./happened-week.component.scss']
})

export class HappenedWeekComponent {
  dateFrom: string;
  dateUntil: string;
  dateUntillData: string
  loggedMasterUser: any;
  clientData: any;
  isLoading = true;
  chartOption: EChartsOption;

  hourlyConsumption: number[] = new Array().fill(0);
  hourlyPrices: number[] = new Array().fill(0);
  hourlyCalculatedPrice: number[] = new Array().fill(0);

  totalWeekConsumption: any = []

  pricesActualData: any
  consumptionData: any

  identifiers: any
  devices: any
  businessUnits: any;

  totalConsumption: any
  totalPrice: any
  totalCalculatedPrice: any

  totalLocationConsumption: any
  totalLocationPrice: any
  totalLocationCalculatedPrice: any

  highestPrice: any

  selectedIdentifier: any = 'All'
  selectedDevice: any = ''
  selectedBusinessUnit: any = '';

  exportType: any='xlsx';

  displayedColumns: string[] = [
    'time',
    'consumption',
    'actualPrice',
    'calculatedPrice',
  ]
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('dialogTemplate2') dialogTemplate2: TemplateRef<any>;

  selectedTabIndex: number
  maxDate:any

  constructor(
    private happenedService: HappenedYesterdayService,
    private businessLocationsService: BusinessUnitLocationsService,
    private adminService: AdminService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    this.setInitialDates();
    this.loggedMasterUser = localStorage.getItem('userToken');
    if (this.loggedMasterUser) {
      this.loggedMasterUser = JSON.parse(this.loggedMasterUser);
      this.adminService.getClient(this.loggedMasterUser.client_id).subscribe(
      { next:  ( data:any ) => {
          this.clientData = data;
        },
        error: (error:any) => {
          this.translate.get('happenedWeek.validator.toastr.error').subscribe((data1: string) => {
            this.translate.get('happenedWeek.validator.toastr.error1').subscribe({ 
              next: (data2: string) => {
                this.toastr.error(data2,data1);
              }
            });      
          });      
        }
      });
    }
  }

  setInitialDates() {
    const date = new Date();

    const currentDayOfWeek = date.getDay();

    const lastSunday = new Date(date);
    lastSunday.setDate(date.getDate() - currentDayOfWeek );

    const lastMondayData = new Date(date);
    lastMondayData.setDate(date.getDate() - currentDayOfWeek+1);

    const lastMonday = new Date(lastSunday);
    lastMonday.setDate(lastSunday.getDate() - 6);

    this.dateUntillData = formatDate(lastMondayData, 'yyyy-MM-dd', 'en');
    this.dateFrom = formatDate(lastMonday, 'yyyy-MM-dd', 'en');
    this.maxDate=this.dateFrom
    this.dateUntil = formatDate(lastSunday, 'yyyy-MM-dd', 'en');
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.getData()
    this.updateChartOption();
  }

  async getData(){
    this.businessLocationsService.getBusinessUnits().subscribe(
      { next: (data: any) => {
            this.businessUnits = data;              
  
            if ( ! this.selectedBusinessUnit ){
              this.selectedBusinessUnit = this.businessUnits[0];
            }
          },
          error: (error: any) => {
            this.translate.get('happenedYesterday.validator.toastr.error').subscribe((data1: string) => {
              this.translate.get('happenedYesterday.validator.toastr.error5').subscribe({ 
                next: (data2: string) => {
                  this.toastr.error(data2,data1);
                }
              });      
            });        
          }
      });
  
      this.happenedService.getWeekActualPrices({ dateFrom: this.dateFrom, dateUntil: this.dateUntil }).subscribe(
      { next: (data:any) => {
          this.pricesActualData = data
          this.processPrices(this.pricesActualData);
          this.happenedService.getHappenedYesterday({ dateFrom: this.dateFrom, dateUntil: this.dateUntillData, businessUnit: this.selectedBusinessUnit.id }).subscribe(
            { next: (data:any) => {
                this.consumptionData = data
                this.getOverallHappened(this.consumptionData);
                this.extractIdentifiers()
            },
            error: (error: any) => {
              this.translate.get('happenedWeek.validator.toastr.error').subscribe((data1: string) => {
                this.translate.get('happenedWeek.validator.toastr.error2').subscribe({ 
                  next: (data2: string) => {
                    this.toastr.error(data2,data1);
                  }
                });      
              });        
            }  
          });
        },
        error: (error:any) => {
          this.translate.get('happenedWeek.validator.toastr.error').subscribe((data1: string) => {
            this.translate.get('happenedWeek.validator.toastr.error3').subscribe({ 
              next: (data2: string) => {
                this.toastr.error(data2,data1);
              }
            });      
          });      
        } 
      });
  }

  generateChart(){
    let date = new Date(this.dateFrom);
    date.setDate(date.getDate());
    this.dateFrom = formatDate(date, 'yyyy-MM-dd', 'en');
    date.setDate(date.getDate()+6);
    this.dateUntil = formatDate(date, 'yyyy-MM-dd', 'en');
    this.dateUntillData = formatDate(date.setDate(date.getDate()+1), 'yyyy-MM-dd', 'en');
    this.isLoading = true;
    this.getData()
  }

  extractIdentifiers() {
    const identifiers: any = [];
    const devices: any = [];

    identifiers.push('All')
    this.consumptionData.forEach((location: any) => {
      if (location.identifier) {
        identifiers.push(location.identifier);
      }
      location.devices.forEach((device: any, index: number) => {
        if (device) {
          devices.push({ label: `Device (${devices.length + 1}) - ${device.description}`, value: device.id, location: device.location_id });
        }
      });
    });
    this.identifiers = identifiers;
    this.devices = devices
  }

  formatDate(dateFrom: any) {
    return this.getFormattedDateWithSuffix(dateFrom);  // Format date
  }

  getFormattedDateWithSuffix(dateString: string): string {
    const date = new Date(dateString);
    const day = date.getDate();
    const suffix = this.getDaySuffix(day);
    const monthYear = this.datePipe.transform(date, 'MMM yyyy');
    return `${this.padZero(day)}${suffix} ${monthYear}`;
  }

  getDaySuffix(day: number): string {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  }

  padZero(day: number): string {
    return day < 10 ? '0' + day : day.toString();
  }

  getOverallHappened(data: any) {
    this.totalWeekConsumption = []
    let hourlyConsumption = new Array();
    let sampleData: any = []
    data.forEach((region: any) => {
      region.devices.forEach((device: any) => {
        device.data.forEach((reading: any) => {
          let datetime = new Date(reading.datetime);
          let hour = datetime.getUTCHours();

          let consumption = parseFloat(reading.consumption);

          if ( reading.unit === "Wh" )  consumption = (consumption / 1000);
          if ( reading.unit === "W" )   consumption = (consumption / 1000);
          if ( reading.unit === "mWh" ) consumption = (consumption * 1000);
          if ( reading.unit === "mW" )  consumption = (consumption * 1000);
          
          if (hourlyConsumption[hour]) hourlyConsumption[hour] += consumption;
          else hourlyConsumption[hour] = consumption;

          let day = datetime.getUTCDate()
          let month = datetime.getUTCMonth() + 1
          let year = datetime.getUTCFullYear()

          let alreadyExist = sampleData.find((item: any) => item.hour == hour && item.day == day && item.month == month && item.year == year)
          if (alreadyExist) {
            alreadyExist.consumption += consumption;
          } else {
            sampleData.push({
              hour: hour,
              day: datetime.getUTCDate(),
              month: datetime.getUTCMonth() + 1,
              year: datetime.getUTCFullYear(),
              consumption: consumption,
              date: reading.datetime
            })
          }
        });
      });
    });

    sampleData.forEach((item: any, index: number) => {
      this.totalWeekConsumption[index] = parseFloat((item.consumption).toFixed(2))
    })

    hourlyConsumption = hourlyConsumption.map(value => parseFloat((value).toFixed(2)));
    this.hourlyConsumption = hourlyConsumption;
    console.log("this.hourlyConsumption", this.hourlyConsumption);

    for (let i = 0; i < this.totalWeekConsumption.length; i++) {
      this.hourlyCalculatedPrice[i] = parseFloat((this.totalWeekConsumption[i] * (this.hourlyPrices[i] ? this.hourlyPrices[i]: 0)).toFixed(2));
    }
    this.updateChartOption();
    this.updateTableData(0)
    this.isLoading = false;
    // this.selectedTabIndex = 0
  }

  setLocation() {
    this.isLoading = true;
    this.hourlyCalculatedPrice = []
    let sampleData: any = []

    if (this.selectedIdentifier) {
      const filteredLocation = this.consumptionData.find((element: any) => element.identifier === this.selectedIdentifier);
      const index = filteredLocation?this.consumptionData.findIndex((element: any) => element.identifier === this.selectedIdentifier):0;
      this.selectedTabIndex=filteredLocation?index+1:0
      this.updateTableData(index)
      let hourlyConsumption = new Array(24).fill(0);

      if(filteredLocation){

        filteredLocation.devices.forEach((device: any) => {
          device.data.forEach((reading: any) => {
            let datetime = new Date(reading.datetime);
            let hour = datetime.getUTCHours();

            let consumption = parseFloat(reading.consumption);

            if ( reading.unit === "Wh" )  consumption = (consumption / 1000);
            if ( reading.unit === "W" )   consumption = (consumption / 1000);
            if ( reading.unit === "mWh" ) consumption = (consumption * 1000);
            if ( reading.unit === "mW" )  consumption = (consumption * 1000);
            
            if (hourlyConsumption[hour]) hourlyConsumption[hour] += consumption;
            else hourlyConsumption[hour] = consumption;

            let day = datetime.getUTCDate()
            let month = datetime.getUTCMonth() + 1
            let year = datetime.getUTCFullYear()

            let alreadyExist = sampleData.find((item: any) => item.hour == hour && item.day == day && item.month == month && item.year == year)
            if (alreadyExist) {
              alreadyExist.consumption += consumption
            } else {
              sampleData.push({
                hour: hour,
                day: datetime.getUTCDate(),
                month: datetime.getUTCMonth() + 1,
                year: datetime.getUTCFullYear(),
                consumption: consumption,
                date: reading.datetime
              })
            }
          });
        });
      }else{
        this.consumptionData.forEach((region: any) => {
          region.devices.forEach((device: any) => {
            device.data.forEach((reading: any) => {
              let datetime = new Date(reading.datetime);
              let hour = datetime.getUTCHours();

              let consumption = parseFloat(reading.consumption);

              if ( reading.unit === "Wh" )  consumption = (consumption / 1000);
              if ( reading.unit === "W" )   consumption = (consumption / 1000);
              if ( reading.unit === "mWh" ) consumption = (consumption * 1000);
              if ( reading.unit === "mW" )  consumption = (consumption * 1000);
              
                  if (hourlyConsumption[hour]) hourlyConsumption[hour] += consumption;
              else hourlyConsumption[hour] = consumption;

              let day = datetime.getUTCDate()
              let month = datetime.getUTCMonth() + 1
              let year = datetime.getUTCFullYear()

              let alreadyExist = sampleData.find((item: any) => item.hour == hour && item.day == day && item.month == month && item.year == year)
              if (alreadyExist) {
                alreadyExist.consumption += consumption
              } else {
                sampleData.push({
                  hour: hour,
                  day: datetime.getUTCDate(),
                  month: datetime.getUTCMonth() + 1,
                  year: datetime.getUTCFullYear(),
                  consumption: consumption,
                  date: reading.datetime
                })
              }
            });
          });
        });
      }

      sampleData.forEach((item: any, index: number) => {
        this.totalWeekConsumption[index] = parseFloat((item.consumption).toFixed(2))
      })

      hourlyConsumption = hourlyConsumption.map(value => parseFloat((value).toFixed(2)));
      this.hourlyConsumption = hourlyConsumption;
      for (let i = 0; i < this.totalWeekConsumption.length; i++) {
        this.hourlyCalculatedPrice[i] = parseFloat((this.totalWeekConsumption[i] * (this.hourlyPrices[i] ? this.hourlyPrices[i]: 0)).toFixed(2));
      }
      this.updateChartOption();
      this.isLoading = false;
    } else {
      this.onLocationChange(0)
      this.getOverallHappened(this.consumptionData)
    }
  }

  updateTableData(index: number) {

    let dataSource: any = []
    let hourlyConsumption = new Array(24).fill(0);
    let sampleData: any = []
    if (index == 0) {
      this.consumptionData.forEach((region: any) => {
        region.devices.forEach((device: any) => {
          device.data.forEach((reading: any) => {
            let datetime = new Date(reading.datetime);
            let hour = datetime.getUTCHours();

            let consumption = parseFloat(reading.consumption);

            if ( reading.unit === "Wh" )  consumption = (consumption / 1000);
            if ( reading.unit === "W" )   consumption = (consumption / 1000);
            if ( reading.unit === "mWh" ) consumption = (consumption * 1000);
            if ( reading.unit === "mW" )  consumption = (consumption * 1000);
            
            if (hourlyConsumption[hour]) hourlyConsumption[hour] += consumption;
            else hourlyConsumption[hour] = consumption;

            let day = datetime.getUTCDate()
            let month = datetime.getUTCMonth() + 1
            let year = datetime.getUTCFullYear()

            let alreadyExist = sampleData.find((item: any) => item.hour == hour && item.day == day && item.month == month && item.year == year)
            if (alreadyExist) {
              alreadyExist.consumption += consumption
            } else {
              sampleData.push({
                hour: hour,
                day: datetime.getUTCDate(),
                month: datetime.getUTCMonth() + 1,
                year: datetime.getUTCFullYear(),
                consumption: consumption,
                date: reading.datetime
              })
            }
          });
        });
      });
      for (let i = 0; i < sampleData.length; i++) {
        let alreadyExist = dataSource.find((item: any) =>
          item.day == sampleData[i].day &&
          item.month == sampleData[i].month &&
          item.year == sampleData[i].year
        );

        if (alreadyExist) {
          alreadyExist.consumption += parseFloat((sampleData[i].consumption).toFixed(2));
          alreadyExist.actualPrice = (parseFloat(alreadyExist.actualPrice) + (this.hourlyPrices[i] ? this.hourlyPrices[i]: 0)).toFixed(2);
          alreadyExist.calculatedPrice += parseFloat(((sampleData[i].consumption) * (this.hourlyPrices[i] ? this.hourlyPrices[i]: 0)).toFixed(2));
        } else {
          if (  this.hourlyPrices[i] && sampleData[i] ){
            let obj = {
              hour: `${sampleData[i].day}.${sampleData[i].month}.${sampleData[i].year}`,
              consumption: parseFloat((sampleData[i].consumption).toFixed(2)),            
              actualPrice: (this.hourlyPrices[i] ? this.hourlyPrices[i]: 0).toFixed(2),
              calculatedPrice: parseFloat(((sampleData[i].consumption) * (this.hourlyPrices[i] ? this.hourlyPrices[i]: 0)).toFixed(2)),
              day: sampleData[i].day,
              month: sampleData[i].month,
              year: sampleData[i].year,
           };
            dataSource.push(obj);
          }
        }
      }
    }
    else {
      index=index-1
      this.consumptionData[index].devices.forEach((device: any) => {
        device.data.forEach((reading: any) => {
          let datetime = new Date(reading.datetime);
          let hour = datetime.getUTCHours();
          let consumption = parseFloat(reading.consumption);
          hourlyConsumption[hour] += consumption;

          if ( reading.unit === "Wh" )  consumption = (consumption / 1000);
          if ( reading.unit === "W" )   consumption = (consumption / 1000);
          if ( reading.unit === "mWh" ) consumption = (consumption * 1000);
          if ( reading.unit === "mW" )  consumption = (consumption * 1000);
          
          if (hourlyConsumption[hour]) hourlyConsumption[hour] += consumption;
          else hourlyConsumption[hour] = consumption;
          let day = datetime.getUTCDate()
          let month = datetime.getUTCMonth() + 1
          let year = datetime.getUTCFullYear()
          let alreadyExist = sampleData.find((item: any) => item.hour == hour && item.day == day && item.month == month && item.year == year)
          if (alreadyExist) {
            alreadyExist.consumption += consumption
          } else {
            sampleData.push({
              hour: hour,
              day: datetime.getUTCDate(),
              month: datetime.getUTCMonth() + 1,
              year: datetime.getUTCFullYear(),
              consumption: consumption,
              date: reading.datetime
            })
          }
        });
      });

      for (let i = 0; i < sampleData.length; i++) {
        let alreadyExist = dataSource.find((item: any) =>
          item.day == sampleData[i].day &&
          item.month == sampleData[i].month &&
          item.year == sampleData[i].year
        );

        if (alreadyExist) {
          alreadyExist.consumption += parseFloat((sampleData[i].consumption).toFixed(2));
          alreadyExist.actualPrice = (parseFloat(alreadyExist.actualPrice) + (this.hourlyPrices[i] ? this.hourlyPrices[i]: 0)).toFixed(2);
          alreadyExist.calculatedPrice += parseFloat(((sampleData[i].consumption) * (this.hourlyPrices[i] ? this.hourlyPrices[i]: 0)).toFixed(2));
        } else {
          let obj = {
            hour: `${sampleData[i].day}.${sampleData[i].month}.${sampleData[i].year}`,
            consumption: parseFloat((sampleData[i].consumption).toFixed(2)),
            actualPrice: (this.hourlyPrices[i] ? this.hourlyPrices[i]: 0).toFixed(2),
            calculatedPrice: parseFloat(((sampleData[i].consumption) * (this.hourlyPrices[i] ? this.hourlyPrices[i]: 0)).toFixed(2)),
            day: sampleData[i].day,
            month: sampleData[i].month,
            year: sampleData[i].year,
          };
          dataSource.push(obj);
        }
      }
    }

    this.totalLocationConsumption = dataSource.reduce((acc: any, curr: any) => acc + curr.consumption, 0);
    this.totalLocationPrice = dataSource.reduce((acc: any, curr: any) => acc + Number(curr.actualPrice), 0);
    this.totalLocationCalculatedPrice = dataSource.reduce((acc: any, curr: any) => acc + curr.calculatedPrice, 0);

    for (let item of dataSource) {
      item.calculatedPrice = item.calculatedPrice.toFixed(2)
      item.consumption = item.consumption.toFixed(2)
    }
    this.dataSource = new MatTableDataSource(dataSource);
    this.dataSource.sort = this.sort;
  }

  getHourName(hour: number) {
    let hourName;
    if (hour === 9) {
      hourName = `0${hour}:00-${hour + 1}:00 hour`;
    } else if (hour < 9) {
      hourName = `0${hour}:00-0${hour + 1}:00 hour`;
    } else {
      hourName = `${hour}:00-${hour + 1}:00 hour`;
    }
    return `${hourName}`;
  }

  getHourNameForTable(hour: number) {
    let hourName;
    let date = this.dateFrom.split("-").reverse().join('.')
    if (hour === 9) {
      hourName = `0${hour}:00-${hour + 1}:00`;
    } else if (hour < 9) {
      hourName = `0${hour}:00-0${hour + 1}:00`;
    } else {
      hourName = `${hour}:00-${hour + 1}:00`;
    }
    return `${date} ${hourName}`;
  }

  processPrices(data: any) {
    data.forEach((price: any, index: number) => {
      let priceValue = parseFloat(price.price) / 100000;
      this.hourlyPrices[index] = priceValue;
    });
    this.hourlyPrices = this.hourlyPrices.map(value => parseFloat(value.toFixed(2)));
  }

  capitalizeFirstLetter(string: string) {
    return string.replace(/\b\w/g, char => char.toUpperCase());
  }

  updateChartOption() {
    // Parse the dateFrom string to create a Date object
    let startDate = new Date(this.dateFrom);

    // Ensure the start date is set to exactly midnight (00:00:00)
    startDate.setHours(0, 0, 0, 0);

    this.totalPrice = 0;
    this.highestPrice = {
      hour: 0,
      price: 0
    };

    for (let i = 0; i < this.hourlyPrices.length; i++) {
      this.totalPrice += this.hourlyPrices[i];
      if (this.hourlyPrices[i] > this.highestPrice.price) {
        this.highestPrice.price = this.hourlyPrices[i];
        // this.highestPrice.hour = i;
      }
    }

    console.log("this.totalWeekConsumption", this.totalWeekConsumption);
    this.totalConsumption = this.totalWeekConsumption.reduce((acc:any, curr:any) => acc + curr, 0);
    this.totalCalculatedPrice = this.hourlyCalculatedPrice.reduce((acc, curr) => acc + curr, 0);

    // Generate x-axis labels for 168 hours
    const xAxisLabels = [];
    for (let i = 0; i < 168; i++) {
      const currentDate = new Date(startDate.getTime() + i * 3600000); // Add hours
      const formattedDate = this.formatDatePlot(currentDate);
      xAxisLabels.push(formattedDate);
    }

    this.chartOption = {
      tooltip: {
        trigger: 'axis',
      },
      legend: {
        data: ['Consumption', 'Actual Price', 'Consumption Cost'],
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100,
        },
        // {
        //   show: true,
        //   type: 'slider',
        //   top: '90%',
        //   start: 0,
        //   end: 100,
        // },
      ],
      xAxis: {
        name: '',
        type: 'category',
        data: xAxisLabels,
        axisLabel: {
          formatter: (value: string) => value,
          interval: 23, // Show label every 24 hours
        },
      },
      yAxis: [
        {
          type: 'value',
          name: 'Consumption (kWh)',
          position: 'left',
        },
        {
          type: 'value',
          name: 'EUR / kWh',
          position: 'right',
          axisLine: {
            lineStyle: { color: '#0083cf' },
          },
          axisLabel: {
            formatter: '{value} €',
          },
        },
      ],
      series: [
        {
          name: 'Consumption Cost',
          type: 'line',
          data: this.hourlyCalculatedPrice,
          lineStyle: { color: '#00bf63', width: 2 },
          itemStyle: { color: '#00bf63' },
          areaStyle: { color: '#00bf6350', opacity: 0.5 },
          smooth: true,
          yAxisIndex: 1, // Linked to the second y-axis
        },
        {
          name: 'Consumption',
          type: 'line',
          data: this.totalWeekConsumption,
          lineStyle: { color: '#000000', width: 2 },
          itemStyle: { color: '#000000', opacity: 0.5 },
          smooth: true,
          yAxisIndex: 0, // Linked to the first y-axis
        },
        {
          name: 'Actual Price',
          type: 'line',
          data: this.hourlyPrices,
          lineStyle: { color: '#777777', width: 2 },
          itemStyle: { color: '#777777' },
          smooth: true,
          yAxisIndex: 1, // Linked to the second y-axis
        },
      ],
    };
  }

  // Helper function to format date
  private formatDatePlot(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }

  setBusinessUnit() {
    //console.log("this.selectedBusinessUnit", this.selectedBusinessUnit);
    this.isLoading = true;
    this.selectedIdentifier = 'All';

    this.happenedService.getYesterdayActualPrices(this.dateFrom).subscribe(
    { next: (data:any ) => {
          this.pricesActualData = data
          this.processPrices(this.pricesActualData);
          
          this.happenedService.getHappenedYesterday({ dateFrom: this.dateFrom, dateUntil: this.dateUntil, businessUnit: this.selectedBusinessUnit.id }).subscribe(
              { next: (data: any) => {
                  this.consumptionData = data
                  this.getOverallHappened(this.consumptionData);
                  this.extractIdentifiers()
                  this.updateChartOption();
                  this.isLoading = false;
  
                  //console.log("consumptionData:",this.consumptionData);
              },
              error: (error: any) => {
                this.translate.get('happenedYesterday.validator.toastr.error').subscribe((data1: string) => {
                  this.translate.get('happenedYesterday.validator.toastr.error2').subscribe({ 
                    next: (data2: string) => {
                      this.toastr.error(data2,data1);
                    }
                  });      
                });        
              }
          });
        },
        error: (error:any) => {
          this.translate.get('happenedYesterday.validator.toastr.error').subscribe((data1: string) => {
            this.translate.get('happenedYesterday.validator.toastr.error3').subscribe({ 
              next: (data2: string) => {
                this.toastr.error(data2,data1);
              }
            });      
          });      
        } 
    });  
  }

  onLocationChange(event: any) {
    this.selectedTabIndex = event
    this.updateTableData(event)
  }

  formatNumber(value: any) {
    let roundedValue = value.toFixed(2).replace('.', '.');
    return roundedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  openExportDailog(){
    this.dialog.open(this.dialogTemplate2);
  }

  exportPlans() {
    let payload: any = {
      dateFrom: this.dateFrom,
      dateUntil: this.dateUntil,
      format:this.exportType
    };
    let requestOptions = {
      responseType: 'blob' as 'json',
    };
    this.happenedService.exportData(payload, requestOptions).subscribe(
      { next: (response: Blob) => {
          this.dialog.closeAll();
          const blobUrl = window.URL.createObjectURL(response);
          const link = document.createElement('a');
          link.href = blobUrl;
          link.download = `Happened Last Week.${this.exportType}`;
          link.click();
          window.URL.revokeObjectURL(blobUrl);
          link.remove();
          this.exportType = '';
        },
        error: (error:any ) => {
          console.error('Error exporting Happened Last Week:', error);
          this.translate.get('happenedWeek.validator.toastr.error').subscribe((data1: string) => {
            this.translate.get('happenedWeek.validator.toastr.error4').subscribe({ 
              next: (data2: string) => {
                this.toastr.error(data2,data1);
              }
            });      
          });      
        }
        }
    )
  }
}
