import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class HappenedYesterdayService {
  baseUrl: string = environment.baseUrlAPI;
  measureDataURL :any = this.baseUrl+'/measure/device-data/client/get-data-by-client';
  actualPriceDataURL :any = this.baseUrl+'/actuals';
  exportDataUrl:any=this.baseUrl+"/measure/export";
  loggedMasterUser:any;

  constructor(private hc: HttpClient) {
    this.loggedMasterUser = localStorage.getItem('userToken');
    if (this.loggedMasterUser) {
      this.loggedMasterUser = JSON.parse(this.loggedMasterUser);
    }
  }

  getHappenedYesterday(payload:any): Observable<any> {
    const headers = new HttpHeaders({
      'x-authorization-token': this.loggedMasterUser.token,
    });
    return this.hc.get(`${this.measureDataURL}/${this.loggedMasterUser.client_id}?from=${payload.dateFrom}&to=${payload.dateUntil}&businessUnit=${payload.businessUnit}`, { headers });
  }

  getYesterdayActualPrices(dateFrom:any): Observable<any> {
    const headers = new HttpHeaders({
      'x-authorization-token': this.loggedMasterUser.token,
    });
    return this.hc.get(`${this.actualPriceDataURL}?from=${dateFrom}&to=${dateFrom}&region=sk`, { headers });
  }

  getWeekActualPrices(payload:any): Observable<any> {
    const headers = new HttpHeaders({
      'x-authorization-token': this.loggedMasterUser.token,
    });
    return this.hc.get(`${this.actualPriceDataURL}?from=${payload.dateFrom}&to=${payload.dateUntil}&region=sk`, { headers });
  }

  exportData(payload:any, options: any): Observable<any> {
    const headers = new HttpHeaders({
      'x-authorization-token': this.loggedMasterUser.token,
    });
    return this.hc.get(`${this.exportDataUrl}?clientId=${this.loggedMasterUser.client_id}&dateFrom=${payload.dateFrom}&dateTo=${payload.dateUntil}&format=${payload.format}`, {...options, headers,responseType: 'blob' });
  }
}
