<title>MyEnergy Analysis: Actual consumption cost</title>
<meta
  name="description"
  content="Digitalisation price tracker of MyEnergy is tracking energy prices in real time and provides a precise forecast."
/>

<div class="pageTemplate">
  <div class="container">
    <div class="headingBlock">
      <div class="headingBlock__title site__title text-center m-3">
        {{ "priceToday.title" | translate }}
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-4">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="inputGroupSelect01">{{
              "priceToday.market" | translate
            }}</label>
          </div>
          <select
            class="custom-select"
            [(ngModel)]="region"
            (change)="setRegion()"
            id="inputGroupSelect01"
          >
            <option selected [ngValue]="'sk'">Slovakia</option>
            <option [ngValue]="'cz'" disabled>Czech Republic</option>
            <option [ngValue]="'hu'" disabled>Hungary</option>
          </select>
        </div>
      </div>

      <!-- <div class="col-12 col-sm-6 col-lg-3"></div> -->
      <div class="col-12 col-sm-4">
        <div class="input-group mb-3 text-right">
          <div class="input-group-prepend">
            <label class="input-group-text" for="inputGroupSelect01"
              >{{ "priceToday.from" | translate }}:</label
            >
          </div>
          <input
            type="date"
            [(ngModel)]="dateFrom"
            class="form-control"
            aria-label="Text input with checkbox"
            value="2023-11-03"
            (change)="generateChart()"
          />
        </div>
      </div>
      <!--
    </div>

    <div class="row">
    -->
      <!--
      <div class="col-12 col-sm-6 col-lg-3">
        <div class="input-group mb-3">
          <select class="custom-select" id="inputGroupSelect02">
            <option selected value="1">hourly data</option>
            <option value="2">weekly data</option>
            <option value="3">monthly data</option>
          </select>
        </div>
      </div>
      -->

      <!-- <div class="col-12 col-sm-6 col-lg-4"></div> -->
      <div class="col-12 col-sm-4">
        <div class="input-group mb-3 text-right">
          <div class="input-group-prepend">
            <label class="input-group-text" for="inputGroupSelect01"
              >{{ "priceToday.until" | translate }}:</label
            >
          </div>
          <input
            type="date"
            [(ngModel)]="dateUntil"
            class="form-control"
            aria-label="Text input with checkbox"
            value="2023-11-03"
            (change)="generateChart()"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="col-12 col-sm-3"
        data-toggle="tooltip"
        data-placement="top"
        title="{{ 'priceToday.tooltip.spot' | translate }}"
      >
        <div class="row">
          <h2 class="row-title col-12">{{ "priceToday.spot" | translate }}</h2>
        </div>
        <div class="row">
          <div class="spotprice-box row-title col-12">
            {{ spotPrice == -999 ? "--" : spotPrice }} €
          </div>
        </div>
      </div>
      <div
        class="col-12 col-sm-3"
        data-toggle="tooltip"
        data-placement="top"
        title="{{ 'priceToday.tooltip.forecast' | translate }}"
      >
        <h2 class="row-title">{{ "priceToday.forecast" | translate }}</h2>
        <div class="backcast-box row-title">
          {{ forecastPrice == -999 ? "--" : forecastPrice }} €
        </div>
      </div>
      <div
        class="col-12 col-sm-2 col-lg-3"
        data-toggle="tooltip"
        data-placement="top"
        title="{{ 'priceToday.tooltip.trend' | translate }}"
      >
        <h2 class="row-title">{{ "priceToday.trend" | translate }}</h2>
        <div class="row-title" *ngIf="trendRising">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="64"
            fill="#00bf63"
            class="bi bi-arrow-up-right-square"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm5.854 8.803a.5.5 0 1 1-.708-.707L9.243 6H6.475a.5.5 0 1 1 0-1h3.975a.5.5 0 0 1 .5.5v3.975a.5.5 0 1 1-1 0V6.707l-4.096 4.096z"
            />
          </svg>
        </div>
        <div class="row-title" *ngIf="!trendRising">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="64"
            fill="#00bf6350"
            class="bi bi-arrow-down-right-square"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm5.854 3.146a.5.5 0 1 0-.708.708L9.243 9.95H6.475a.5.5 0 1 0 0 1h3.975a.5.5 0 0 0 .5-.5V6.475a.5.5 0 1 0-1 0v2.768z"
            />
          </svg>
        </div>
      </div>
      <div
        class="col-12 col-sm-4 col-lg-3"
        data-toggle="tooltip"
        data-placement="top"
        title="{{ 'priceToday.tooltip.semaphor' | translate }}"
      >
        <div (click)="showSemaphore()">
          <h2 class="row-title">
            {{ "priceToday.semaphor.title2" | translate }}
          </h2>
          <div class="semaphore-box row">
            <div class="col-sm-4">
              <img
                src="./assets/images/semaphore-{{ semaphoreColors[0] }}.png"
              />
              <p class="semaphore-text">{{ semaphoreValues[0].toFixed(2) }}</p>
            </div>
            <div class="col-sm-4">
              <img
                src="./assets/images/semaphore-{{ semaphoreColors[1] }}.png"
              />
              <p class="semaphore-text">{{ semaphoreValues[1].toFixed(2) }}</p>
            </div>
            <div class="col-sm-4">
              <img
                src="./assets/images/semaphore-{{ semaphoreColors[2] }}.png"
              />
              <p class="semaphore-text">{{ semaphoreValues[2].toFixed(2) }}</p>
            </div>
          </div>
          <p class="semaphore-note">Click for more details</p>
        </div>
      </div>
    </div>
    <body class="graf img-fluid" #graf></body>
    <div>
      <!-- <ngx-uplot [data]="plotData" [options]="plotOptions" [legend]="true">
      </ngx-uplot> -->
      <div
        echarts
        [options]="chartOption"
        [loading]="isLoading"
        theme="macarons"
        class="demo-chart"
        id="demo-chart"
      ></div>
    </div>
    <div class="row p-3"></div>
    <!-- <div class="row pb-2">
      <div class="col-12 m-0 text-start legend-title">
        {{ "priceToday.chartLegend.subject" | translate }}
      </div>
    </div>
    <div class="row">
      <div class="col-12 m-0">
        {{ "priceToday.chartLegend.spot" | translate }}
      </div>
    </div>
    <div class="row">
      <div class="col-12 m-0">
        {{ "priceToday.chartLegend.forecast" | translate }}
      </div>
    </div>
    <div class="row">
      <div class="col-12 m-0">
        {{ "priceToday.chartLegend.trend" | translate }}
      </div>
    </div>
    <div class="row p-2"></div>
    <div class="row">
      <div class="col-12 m-0 text-start black-text">
        {{ "priceToday.chartLegend.black" | translate }}
      </div>
    </div>
    <div class="row">
      <div class="col-12 m-0 text-start blue-text">
        {{ "priceToday.chartLegend.blue" | translate }}
      </div>
    </div>
    <div class="row">
      <div class="col-12 m-0 text-start green-text">
        {{ "priceToday.chartLegend.green" | translate }}
      </div>
    </div>
    <div class="row p-2"></div>
    <div class="row pb-2">
      <div class="col-12 m-0 legend-title">
        {{ "priceToday.chartLegend.instructions" | translate }}
      </div>
    </div>
    <div class="row">
      <div class="col-12 m-0">
        {{ "priceToday.chartLegend.instructions1" | translate }}
      </div>
    </div>
    <div class="row">
      <div class="col-12 m-0">
        {{ "priceToday.chartLegend.instructions2" | translate }}
      </div>
    </div>
    <div class="row">
      <div class="col-12 m-0">
        {{ "priceToday.chartLegend.instructions3" | translate }}
      </div>
    </div>
    <div class="row">
      <div class="col-12 m-0">
        {{ "priceToday.chartLegend.instructions4" | translate }}
      </div>
    </div>
  </div> -->
  </div>
  <div
    class="modal fade detailsModel"
    id="viewModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="viewModalLabel"
    aria-hidden="true"
    #viewModal
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content container">
        <div class="modal-header">
          <h5 class="modal-title" id="viewModalLabel">
            {{ "priceToday.semaphor.title" | translate }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <!-- selectedPlan?.plan_type == 'day' -->
        <div class="modal-body">
          <div class="PlanBox">
            <div class="PlanBox__inner row">
              <label>{{ "priceToday.semaphor.text1" | translate }}</label>
              <p>{{ semaphoreValues[1].toFixed(2) }}</p>
            </div>
            <div class="PlanBox__inner row">
              <label>{{ "priceToday.semaphor.text2" | translate }}</label>
              <p>{{ semaphoreValues[0].toFixed(2) }}</p>
            </div>
            <div class="PlanBox__inner row">
              <label>{{ "priceToday.semaphor.text3" | translate }}</label>
              <p>{{ semaphoreValues[2].toFixed(2) }}</p>
            </div>
            <div class="PlanBox__inner row">
              <h4>{{ "priceToday.semaphor.text4" | translate }}</h4>
            </div>
            <table class="table table-borderless table-responsive-sm">
              <tbody>
                <tr class="PlanBox__inner__smaller row d-flex">
                  <td class="col-2 align-middle">
                    {{ "priceToday.semaphor.text5" | translate }}
                  </td>
                  <td class="col-1 align-middle">
                    <img
                      src="./assets/images/semaphore-{{
                        semaphoreColors[0]
                      }}.png"
                    />
                  </td>
                  <td class="col-8 align-middle">
                    <div *ngIf="semaphoreColors[0] == 1">
                      {{ "priceToday.semaphor.text6" | translate }} ({{
                        semaphoreValues[1].toFixed(2)
                      }}) {{ "priceToday.semaphor.text7" | translate }} ({{
                        semaphoreValues[0].toFixed(2)
                      }}).
                    </div>
                    <div *ngIf="semaphoreColors[0] == 2">
                      {{ "priceToday.semaphor.text6" | translate }} ({{
                        semaphoreValues[1].toFixed(2)
                      }}) {{ "priceToday.semaphor.text8" | translate }} ({{
                        semaphoreValues[0].toFixed(2)
                      }}).
                    </div>
                    <div *ngIf="semaphoreColors[0] == 3">
                      {{ "priceToday.semaphor.text6" | translate }} ({{
                        semaphoreValues[1].toFixed(2)
                      }}) {{ "priceToday.semaphor.text9" | translate }} ({{
                        semaphoreValues[0].toFixed(2)
                      }}).
                    </div>
                  </td>
                </tr>
                <!-- end of row-->
                <tr class="PlanBox__inner__smaller row d-flex">
                  <td class="col-2 align-middle">
                    {{ "priceToday.semaphor.text10" | translate }}
                  </td>
                  <td class="col-1 align-middle">
                    <img
                      src="./assets/images/semaphore-{{
                        semaphoreColors[1]
                      }}.png"
                    />
                  </td>
                  <td class="col-8 align-middle">
                    <div *ngIf="semaphoreColors[1] == 4">
                      {{ "priceToday.semaphor.text11" | translate }}
                    </div>
                    <div *ngIf="semaphoreColors[1] == 5">
                      {{ "priceToday.semaphor.text12" | translate }}
                    </div>
                    <div *ngIf="semaphoreColors[1] == 6">
                      {{ "priceToday.semaphor.text13" | translate }}
                    </div>
                  </td>
                </tr>
                <tr class="PlanBox__inner__smaller row d-flex">
                  <td class="col-2 align-middle">
                    {{ "priceToday.semaphor.text14" | translate }}
                  </td>
                  <td class="col-1 align-middle">
                    <img
                      src="./assets/images/semaphore-{{
                        semaphoreColors[2]
                      }}.png"
                    />
                  </td>
                  <td class="col-8 align-middle">
                    <div *ngIf="semaphoreColors[2] == 1">
                      {{ "priceToday.semaphor.text6" | translate }} ({{
                        semaphoreValues[1].toFixed(2)
                      }}) {{ "priceToday.semaphor.text15" | translate }} ({{
                        semaphoreValues[2].toFixed(2)
                      }}).
                    </div>
                    <div *ngIf="semaphoreColors[2] == 2">
                      {{ "priceToday.semaphor.text6" | translate }} ({{
                        semaphoreValues[1].toFixed(2)
                      }}) {{ "priceToday.semaphor.text16" | translate }} ({{
                        semaphoreValues[2].toFixed(2)
                      }}).
                    </div>
                    <div *ngIf="semaphoreColors[2] == 3">
                      {{ "priceToday.semaphor.text6" | translate }} ({{
                        semaphoreValues[1].toFixed(2)
                      }}) {{ "priceToday.semaphor.text17" | translate }} ({{
                        semaphoreValues[2].toFixed(2)
                      }}).
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
