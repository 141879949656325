<title>MyEnergy Analysis: Happened Yesterday</title>
<meta
  name="description"
  content="Digitalisation price tracker of MyEnergy is tracking energy prices in real time and provides a precise forecast."
/>

<div class="pageTemplate">
  <div class="container">
    <div class="headingBlock">
      <div
        *ngIf="clientData"
        class="headingBlock__title site__title text-center m-3"
      >
        <!-- {{ "priceToday.title" | translate }} -->
        {{ "happenedMonth.title" | translate }}
        <span style="color: #00bf63">
          {{ clientData?.name + " ( " + clientData?.shortName + " ) " }}</span
        >
        {{ formatDate(dateFrom) }} -{{ formatDate(dateUntil) }}
      </div>
    </div>
    <div class="col-3 fliter">
      {{ "happenedMonth.filterOptions" | translate }} :
    </div>
    <div class="row stickyHeader" style="justify-content: center">
      <div class="col-12 col-sm-8 col-lg-3">
        <div class="input-group mb-3 text-right">
          <div class="input-group-prepend">
            <label class="input-group-text" for="inputGroupSelect01"
              >{{ "priceToday.from" | translate }}:</label
            >
          </div>
          <input
            type="date"
            [(ngModel)]="dateFrom"
            [max]="maxDate"
            class="form-control"
            aria-label="Text input with checkbox"
            value="2023-11-03"
            (change)="generateChart()"
          />
        </div>
      </div>
      <div class="col-12 col-sm-6 col-lg-5">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="inputGroupSelect01">{{
              "happenedMonth.businessUnit" | translate
            }}</label>
          </div>
          <select
            class="custom-select"
            [(ngModel)]="selectedBusinessUnit"
            (change)="setBusinessUnit()"
            id="inputGroupSelect01"
          >
            <option [ngValue]="item" *ngFor="let item of businessUnits">
              {{ item.identifier }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-lg-4">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="inputGroupSelect01">{{
              "happenedMonth.locations" | translate
            }}</label>
          </div>
          <select
            class="custom-select"
            [(ngModel)]="selectedIdentifier"
            (change)="setLocation()"
            id="inputGroupSelect01"
          >
            <option [ngValue]="item" *ngFor="let item of identifiers">
              {{ capitalizeFirstLetter(item) }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row" style="justify-content: center">
      <div class="col-4 overall-info">
        {{ "happenedMonth.avgMonthConsumption" | translate }}
        <span style="color: #00bf63">
          {{ (totalConsumption / 168).toFixed(2) }}kWh</span
        >
      </div>

      <div class="col-4 overall-info">
        {{ "happenedMonth.avgMonthPrice" | translate }}
        <span style="color: #00bf63">
          {{ formatNumber(totalPrice / 168) }}€</span
        >
      </div>
      <div class="col-4 overall-info">
        {{ "happenedMonth.avgConsumptionPrice" | translate }}:
        <span style="color: #00bf63">
          {{ formatNumber(totalCalculatedPrice / 168) }}€/hour</span
        >
      </div>
    </div>
    <div class="row" style="justify-content: center">
      <div class="col-4 overall-info">
        {{ "happenedMonth.totalMonthConsumption" | translate }}
        <span style="color: #00bf63"> {{ totalConsumption.toFixed(2) }}kW</span>
      </div>
      <div class="col-4 overall-info">
        {{ "happenedMonth.highestMonthPrice" | translate }}
        <span style="color: #00bf63">
          {{ formatNumber(this.highestPrice.price) }}€</span
        >
        <!-- ({{getHourName(this.highestPrice.hour)}}) -->
      </div>
      <div class="col-4 overall-info">
        {{ "happenedMonth.totalConsumptionPrice" | translate }}:
        <span style="color: #00bf63">
          {{ formatNumber(totalCalculatedPrice) }}€</span
        >
      </div>
    </div>

    <body class="graf img-fluid" #graf></body>
    <div>
      <!-- <ngx-uplot [data]="plotData" [options]="plotOptions" [legend]="true">
      </ngx-uplot> -->
      <div
        echarts
        [options]="chartOption"
        [loading]="isLoading"
        theme="macarons"
        class="demo-chart"
      ></div>
    </div>

    <div *ngIf="clientData" class="headingBlock">
      <div class="row" style="justify-content: space-evenly; gap: 150px">
        <div *ngIf="clientData" class="fliter">
          <!-- {{ "priceToday.title" | translate }} -->
          {{ "happenedMonth.title" | translate }}
          <span style="color: #00bf63">
            {{ clientData?.name + " ( " + clientData?.shortName + " ) " }}</span
          >
          {{ formatDate(dateFrom) }} -{{ formatDate(dateUntil) }} :
        </div>
        <button (click)="openExportDailog()" class="exportBtn">
          {{ "happenedMonth.exportBtn" | translate }}
        </button>
      </div>
    </div>

    <mat-tab-group
      *ngIf="clientData"
      [(selectedIndex)]="selectedTabIndex"
      (selectedIndexChange)="onLocationChange($event)"
    >
      <mat-tab
        disabled="true"
        [label]="capitalizeFirstLetter(item)"
        *ngFor="let item of identifiers"
      >
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <!-- Time Column -->
          <ng-container matColumnDef="time">
            <th mat-header-cell *matHeaderCellDef>
              {{ "happenedMonth.time" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.hour }}
            </td>
            <td mat-footer-cell *matFooterCellDef>
              {{ "happenedMonth.tableTotal" | translate }}
            </td>
          </ng-container>

          <!-- Consumption Column -->
          <ng-container matColumnDef="consumption">
            <th mat-header-cell *matHeaderCellDef>
              {{ "happenedMonth.consumption" | translate }} (kW)
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.consumption }}
            </td>
            <td mat-footer-cell *matFooterCellDef>
              {{ totalLocationConsumption.toFixed(2) }}
            </td>
          </ng-container>

          <!-- Actual Price Column -->
          <ng-container matColumnDef="actualPrice">
            <th mat-header-cell *matHeaderCellDef>
              {{ "happenedMonth.actualPrice" | translate }} (€)
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.actualPrice }}
            </td>
            <td mat-footer-cell *matFooterCellDef>
              {{ totalLocationPrice.toFixed(2) }}
            </td>
          </ng-container>

          <!-- Calculated Price Column -->
          <ng-container matColumnDef="calculatedPrice">
            <th mat-header-cell *matHeaderCellDef>
              {{ "happenedMonth.calculatedPrice" | translate }} (€)
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.calculatedPrice }}
            </td>
            <td mat-footer-cell *matFooterCellDef>
              {{ formatNumber(totalLocationCalculatedPrice) }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
        </table>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
<ng-template #dialogTemplate2>
  <h1
    mat-dialog-title
    style="
      font-weight: 600;
      font-size: 22px;
      border-bottom: 1px solid rgb(201, 201, 201);
    "
  >
    {{ "plans.exportFormat" | translate }}
  </h1>
  <mat-dialog-content class="dialog-content">
    <div class="form-group">
      <select
        size="2"
        class="btn form-control px-2 text-left"
        id="myEnergyDropDwn"
        name="myEnergyDropDwn"
        type="button"
        aria-haspopup="true"
        aria-expanded="false"
        [(ngModel)]="exportType"
      >
        <option selected value="csv">CSV</option>
        <option value="xlsx">XLSX</option>
      </select>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions
    style="display: flex; justify-content: center; align-content: center"
  >
    <button class="theme-btn-yellow" mat-dialog-close (click)="exportPlans()">
      {{ "plans.exportBtn" | translate }}
    </button>
  </mat-dialog-actions>
</ng-template>
