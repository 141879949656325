import { UserToken } from './../../user/user-token';
import { Component, numberAttribute, OnDestroy } from '@angular/core';
import { HelpdeskService } from '../helpdesk.service';
import { Router } from '@angular/router';
import { Request } from '../request/request';
import { ToastrService } from 'ngx-toastr';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-response',
  templateUrl: './response.component.html',
  styleUrls: ['./response.component.scss'],
})
export class ResponseComponent implements OnDestroy{
  buttonContent: string = 'Submit';
  buttonClass: string = '';
  returnTo: string = '/';
  submitForm: boolean = false;

  requestTitle: string = this.helpdeskService.getRequestTitle();
  requestId: string =
    this.helpdeskService.getRequestId() != ''
      ? this.helpdeskService.getRequestId()
      : '';
  requestSubject: string = this.helpdeskService.getRequestSubject();
  myEnergyService: string = this.helpdeskService.getMyEnergyService();
  description: string = this.helpdeskService.getDescription();
  priority: string = this.helpdeskService.getPriority();
  phoneState: string = this.helpdeskService.getPhoneState();
  phoneNumber: string = this.helpdeskService.getPhoneNumber();
  attachment: File | any = this.helpdeskService.getAttachment();
  email: string = this.helpdeskService.getEmail();

  prefilledData: any;
  registered: boolean = false;

  constructor(
    private helpdeskService: HelpdeskService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
      this.prefilledData = helpdeskService.prefilledData;
  }

  ngOnDestroy(): void {
    this.onClose(null);
  }

  btnSubmitClick(event: Event): void {
    this.buttonClass = 'btn-success';
  }

  genRequestId(): string {
    const id = Math.floor(Math.random() * (999999 - 100000)) + 100000;

    this.helpdeskService.setRequestId(id.toString());
    return id.toString();
  }

  routeChecker(url:string){
    var selectedLang = sessionStorage.getItem('language');

    if ( ! selectedLang ) selectedLang = "en";

    console.log(`${selectedLang}/${url}`);
    this.router.navigate([`${selectedLang}/${url}`]);
    console.log(`${selectedLang}/${url}`);
  }

  onSubmit(): void {
    this.helpdeskService.setRequest(false);
    this.helpdeskService.setRequestTitle(
      'Your help desk request ID ' + this.helpdeskService.getRequestId()
    );
    this.requestTitle = this.helpdeskService.getRequestTitle();
    this.routeChecker('helpdesk')
    this.submitForm = true;

    let data = this.constructRestData();

    //console.log('Token :', this.helpdeskService.userToken);
    //console.log('Request : ', data);

    this.helpdeskService.postNewRequest(data).subscribe({
      next: (data) => {
        this.helpdeskService.setRequestId(data?.id);
        this.requestId = this.helpdeskService.getRequestId();

        this.helpdeskService.setRequestTitle(
          'Your help desk request ID ' + this.helpdeskService.getRequestId()
        );
        this.requestTitle = this.helpdeskService.getRequestTitle();
        this.routeChecker('helpdesk')
        this.submitForm = true;
        this.toastr.success('Submitted Successfully!', '');
        //this.helpdeskService.setRequest(true);
        //console.log('Request ID: ', this.requestId);
      },

      error: (err: any) => {
        console.log(err);
      },
    });

  }

  onEdit(): void {
    console.log("som v onEdit");
    this.buttonClass = 'btn-success';
    this.helpdeskService.setRequest(true);
    console.log("odchadzam do request");
    this.routeChecker('helpdesk');
  }

  onClose(event:any): void {

    if ( event ){
      this.routeChecker('home');
      this.submitForm = true;
      this.helpdeskService.resetData();
    } else {
      this.helpdeskService.setRequest(true);
    }
  }

  constructRestData(): any {
    const request: Request = {
      user_id: this.helpdeskService.userToken?.id!.toString(),
      client_id:
        this.helpdeskService.userToken?.client_id! === null ||
        this.helpdeskService.userToken?.client_id! === 0
          ? '1'
          : this.helpdeskService.userToken?.client_id!.toString(),
      subject: this.requestSubject,
      service: this.myEnergyService,
      body: this.description,
      priority: this.priority.substr(0, 1).toUpperCase(),
      phone: this.phoneState + this.phoneNumber,
      //attachment: File,
      email: this.email,
      status: 'new',
    };

    return request;
  }
}
